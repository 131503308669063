import { Fragment, useEffect, useState } from "react";
import {
  Layout,
  Button,
  Select,
  Form,
  Input,
  DatePicker,
  Typography,
  Image,
  Tooltip,
  message,
  Alert,
  Steps,
  Result,
  Progress,
  ConfigProvider,
  theme,
  Switch,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import useLocalStorage from "use-local-storage";

import {
  FORM_LAYOUT_PROPS,
  SINGLE_ITEM_LAYOUT_PROPS,
  MONTH_FORMAT,
  DEFAULT_CAPTCHA_URL,
  ENDPOINTS,
  LU_OPERATION_TYPE,
  LU_PROCESS_ERROR,
  LU_STATUS_NAME,
} from "./constants";
import {
  SessionStartResponse,
  AnyApiResponse,
  FormData,
  ProcessPayload,
  ProcessResponse,
  DataRequested,
  StatusData,
} from "./interface";
import { downloadFile, getWindowSize } from "./utils";

import "./App.css";

const { Link } = Typography;
const { Header, Footer, Content } = Layout;
const { Option } = Select;
const { Password } = Input;
const { Step } = Steps;

const API_HOST = process.env.REACT_APP_API_HOST || "";

const App = () => {
  const [form] = Form.useForm<FormData>();

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [sessionId, setSessionId] = useState("");
  const [dataRequested, setDataRequested] =
    useLocalStorage<DataRequested | null>("dataRequested", null);

  const [captchaUrl, setCaptchaUrl] = useState(DEFAULT_CAPTCHA_URL);

  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const [statusData, setStatusData] = useState<StatusData | null>(null);

  const setTheme = (darkMode: boolean) => {
    localStorage.setItem("theme", darkMode ? "1" : "0");
    setDarkMode(darkMode);
  };

  const loadTheme = () => {
    const theme = localStorage.getItem("theme") || "0";
    setDarkMode(theme === "1");
  };

  const onFinish = (values: FormData) => {
    if (!sessionId) {
      message.warning("Primero debe completar el código", 5);
      return;
    }

    // message.info("La consulta ha iniciado", 3);
    setLoadingForm(true);
    const payload: ProcessPayload = {
      operationType: values.operationType,
      rfc: values.rfc.toUpperCase(),
      password: values.password,
      email: values.email.toLocaleLowerCase(),
      captcha: values.captcha.toUpperCase(),
      period: values.period.format(MONTH_FORMAT),
    };
    axios
      .post(ENDPOINTS.PROCESS({ API_HOST, sessionId }), payload)
      .then((resp) => {
        const responseData: AnyApiResponse<ProcessResponse> = resp.data;
        const data = responseData.data;
        message.success(
          "Su solicitud se ha colocado en cola, muy pronto comenzará la descarga de sus facturas, y al finalizar, se enviarán a su correo"
        );
        onReset();
        setDataRequested({
          operationType: LU_OPERATION_TYPE[payload.operationType],
          period: payload.period,
          rfc: payload.rfc,
          email: payload.email,
          processId: data.processId,
        });
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          message.error(error.message, 5);
          setCaptchaUrl(DEFAULT_CAPTCHA_URL);
          setSessionId("");
          form.setFieldValue("captcha", "");
          if (error.message === LU_PROCESS_ERROR.LOGIN) {
            form.setFieldValue("password", "");
          }
          onReloadCaptcha();
        } else {
          message.error(
            "Ocurrió un problema al procesar su solicitud, favor de reintentar",
            5
          );
        }
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingForm(false);
        }, 100);
      });
  };

  const onReloadCaptcha = () => {
    console.log(dataRequested);
    if (dataRequested !== null) {
      return;
    }

    setLoadingCaptcha(true);
    axios
      .get(ENDPOINTS.SESSION_START({ API_HOST }))
      .then((resp) => {
        const responseData: AnyApiResponse<SessionStartResponse> = resp.data;
        const data = responseData.data;
        const { sessionId, captchaUrl } = data;
        setCaptchaUrl(captchaUrl);
        setSessionId(sessionId);
        form.setFieldValue("captcha", "");
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          message.error(error.message, 5);
        } else {
          message.error(
            "Ocurrió un problema al traer un código, favor de reintentar",
            5
          );
        }
        console.log(err);
        setCaptchaUrl(DEFAULT_CAPTCHA_URL);
        setSessionId("");
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingCaptcha(false);
        }, 100);
      });
  };

  const onProcessStatus = () => {
    if (
      dataRequested === null ||
      (statusData && statusData.stepName === LU_STATUS_NAME.COMPLETED)
    ) {
      return;
    }

    axios
      .get(ENDPOINTS.STATUS({ API_HOST, processId: dataRequested.processId }))
      .then((resp) => {
        const responseData: AnyApiResponse<StatusData> = resp.data;
        const data = responseData.data;
        setStatusData((prevState) => data);
        /*if (
          [LU_STATUS_NAME.COMPLETED, LU_STATUS_NAME.ERROR].indexOf(
            data.stepName
          ) !== -1
        ) {
          setTimeout(() => {
            onReboot();
          }, 5 * 1000);
        }*/
        if (data.stepName === LU_STATUS_NAME.COMPLETED && data.files) {
          data.files.forEach((item) => {
            downloadFile(item.url, item.filename);
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          const error = err.response.data;
          message.error(error.message, 5);
        } else {
          message.error(
            "Ocurrió un problema al mostrar el estatus, favor de reintentar",
            5
          );
        }
        console.log(err);
      });
  };

  const onReset = () => {
    form.resetFields();
    // message.info("Se ha limpiado el formulario", 3);
  };

  const onReboot = () => {
    setDataRequested(null);
    setStatusData(null);
    setTimeout(() => {
      onReloadCaptcha();
    }, 1000);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    onReloadCaptcha();
    const intervalId = setInterval(() => {
      onReloadCaptcha();
    }, 3 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [dataRequested]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      onProcessStatus();
    }, 5 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [dataRequested, statusData]);

  useEffect(() => {
    loadTheme();
  }, []);

  const singleItemLayoutProps =
    windowSize.innerWidth > 575 ? SINGLE_ITEM_LAYOUT_PROPS : {};

  const formLayoutProps = windowSize.innerWidth > 575 ? FORM_LAYOUT_PROPS : {};
  const formLayout = windowSize.innerWidth > 575 ? "horizontal" : "vertical";
  console.log({ formLayout });

  const renderForm = () => (
    <Form
      {...formLayoutProps}
      form={form}
      layout={formLayout}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={{
        operationType: null,
        period: null,
        rfc: "",
        password: "",
        email: "",
        captcha: "",
      }}
      disabled={dataRequested !== null}
      className={dataRequested !== null ? "hidden" : ""}
    >
      <Form.Item
        name="operationType"
        label="Elija tipo de operación"
        rules={[
          {
            required: true,
            message: "La operación es requerida",
          },
        ]}
      >
        <Select placeholder="Elija una operación">
          <Option value="2">Facturas recibidas</Option>
          <Option value="1">Facturas emitidas</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="period"
        label="Periodo"
        rules={[{ required: true, message: "El periodo es requerido" }]}
      >
        <DatePicker
          className="w-full"
          picker="month"
          format={MONTH_FORMAT}
          inputReadOnly
          placeholder="Elija un periodo"
        />
      </Form.Item>
      <Form.Item
        name="rfc"
        label="Su RFC"
        rules={[
          { required: true, message: "El RFC es requerido" },
          {
            pattern:
              /^([A-Za-z]{3,4})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Za-z\d]{3})?$/,
            message: "El formato del RFC no es válido",
          },
        ]}
      >
        <Input
          maxLength={13}
          style={{ textTransform: "uppercase" }}
          placeholder="Ej. GOMA780413RF4"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Contraseña del SAT"
        rules={[{ required: true, message: "La contraseña es requerida" }]}
      >
        <Password
          data-dependency="rfc"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          placeholder="Su contraseña"
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Correo para enviar sus facturas"
        rules={[
          { required: true, message: "El correo es requerido" },
          {
            type: "email",
            message: "El formato del correo no es válido",
          },
        ]}
      >
        <Input
          type="email"
          data-dependency="password"
          placeholder="Ej. arturo.gonzalez.78@gmail.com"
          style={{ textTransform: "lowercase" }}
        />
      </Form.Item>
      <Form.Item {...singleItemLayoutProps}>
        <div className="text-center">
          <Image
            width={160}
            height={60}
            src={captchaUrl}
            preview={false}
            loading={loadingCaptcha ? "lazy" : undefined}
          />
        </div>
      </Form.Item>
      <Form.Item label="Escriba el código">
        <Input.Group compact>
          <Form.Item
            noStyle
            name="captcha"
            rules={[
              { required: true, message: "El código es requerido" },
              { len: 6, message: "El código debe ser de 6 caracteres" },
            ]}
          >
            <Input
              maxLength={6}
              style={{ width: "calc(100% - 47px)", textTransform: "uppercase" }}
              data-dependency="email"
              placeholder="Ej. A5x8ft"
            />
          </Form.Item>
          <Tooltip placement="top" title="Cambiar código">
            <Button
              htmlType="button"
              onClick={onReloadCaptcha}
              disabled={loadingForm}
              loading={loadingCaptcha}
            >
              {loadingCaptcha ? "" : <ReloadOutlined />}
            </Button>
          </Tooltip>
        </Input.Group>
      </Form.Item>
      <Form.Item {...singleItemLayoutProps}>
        <div className="flex justify-between">
          <Button
            htmlType="button"
            onClick={onReset}
            disabled={loadingCaptcha || loadingForm}
          >
            Reiniciar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loadingCaptcha || !sessionId}
            loading={loadingForm}
          >
            Consultar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  const renderAlert = () => {
    if (dataRequested === null) {
      return <Fragment />;
    }
    return (
      <Alert
        className="mt-4"
        message="Descarga de facturas en progreso"
        description={`Operación: facturas ${dataRequested.operationType.toLocaleLowerCase()}; periodo: ${
          dataRequested.period
        }, RFC: ${dataRequested.rfc}, se enviarán al correo '${
          dataRequested.email
        }'. Si desea realizar una nueva consulta, debe esperar a que el proceso actual concluya`}
        type="info"
      />
    );
  };

  const renderStepContent = () => {
    if (
      !statusData ||
      [2, 3].indexOf(statusData.step) === -1 ||
      statusData.stepName === LU_STATUS_NAME.ERROR
    ) {
      return <Fragment />;
    }
    let percentCompleted = 0;
    if (statusData.step === 2 && statusData.queue) {
      percentCompleted =
        (statusData.queue.position / statusData.queue.size) * 100;
    } else if (statusData.step === 3 && statusData.download) {
      percentCompleted =
        (statusData.download.currentInvoices /
          statusData.download.totalInvoices) *
        100;
    }
    return (
      <div className="mt-4">
        <LoadingOutlined style={{ fontSize: 32 }} spin />
        <Progress
          percent={parseFloat(percentCompleted.toFixed(2))}
          status="active"
        />
      </div>
    );
  };

  const renderResult = () => {
    const hasError = statusData && statusData.stepName === LU_STATUS_NAME.ERROR;
    if (statusData && statusData.step !== statusData.totalSteps && !hasError) {
      return <Fragment />;
    }

    const hasInvoices = (statusData?.download?.totalInvoices || 0) > 0;
    const resultStatus = hasError ? "error" : hasInvoices ? "success" : "info";
    const resultTitle = hasError
      ? "Hubo un error al descargar, favor de reintentar la consulta"
      : hasInvoices
      ? "Se han descargado y enviado sus facturas con éxito"
      : "No hemos encontrado facturas para el periodo elegido";
    return (
      <Result
        status={resultStatus}
        title={resultTitle}
        extra={
          <Button type="primary" key="console" onClick={onReboot}>
            Nueva consulta
          </Button>
        }
      />
    );
  };

  const renderSteps = () => {
    if (statusData === null) {
      return <Fragment />;
    }
    let statusStep3: "wait" | "process" | "finish" | "error" = "wait";
    if (statusData.step === 3) {
      if (statusData.stepName === LU_STATUS_NAME.ERROR) {
        statusStep3 = "error";
      } else {
        statusStep3 = "process";
      }
    } else if (statusData.step > 3) {
      statusStep3 = "finish";
    }
    return (
      <div className="mt-4 text-center">
        <Steps
          current={statusData.step - 1}
          percent={statusData.percentCompleted}
          status={statusData.percentCompleted >= 100 ? "finish" : "process"}
        >
          <Step title="Recibido" description="Hemos recibido su solicitud" />
          <Step
            title="En Cola"
            subTitle={
              statusData.step === 2 &&
              statusData.queue &&
              `${statusData.queue.position} adelante`
            }
            description="Tus facturas se descargarán a continuación"
          />
          <Step
            title="Descarga"
            subTitle={
              statusData.step === 3 &&
              statusData.download &&
              statusData.stepName !== LU_STATUS_NAME.ERROR &&
              `${statusData.download.currentInvoices} de ${statusData.download.totalInvoices}`
            }
            description={
              statusData.stepName !== LU_STATUS_NAME.ERROR
                ? "Tus facturas se descargarán y enviarán"
                : "Error al descargar"
            }
            status={statusStep3}
          />
          <Step title="Completado" description="Tus facturas se han enviado" />
        </Steps>
        {renderStepContent()}
        {renderResult()}
      </div>
    );
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Header>
          <div className="flex">
            <div className="flex-1 text-center">
              <span className="text-xl text-gray-50">Mis Facturas SAT</span>
            </div>
            <div className="flex-none">
              <Switch
                checkedChildren="Día"
                unCheckedChildren="Noche"
                checked={!darkMode}
                onChange={() => {
                  setTheme(!darkMode);
                }}
              />
            </div>
          </div>
        </Header>
        <Content>
          <div className="flex flex-col items-center p-4">
            {renderForm()}
            {renderAlert()}
            {renderSteps()}
          </div>
        </Content>
        <Footer className="text-center">
          <ul className="mb-0">
            <li>
              Powered by{" "}
              <Link href="https://bit.ly/cu-facturio" target="_blank">
                Consulta Única
              </Link>
            </li>
            <li>
              <Link href="https://bit.ly/cu-tg1" target="_blank">
                Telegram
              </Link>
            </li>
            <li>
              <Link href="https://bit.ly/cu-wa" target="_blank">
                WhatsApp
              </Link>
            </li>
            <li>
              <Link href="https://bit.ly/cu-tiktok" target="_blank">
                TikTok
              </Link>
            </li>
          </ul>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
