import { HashInt } from "./interface";
import { template } from "./utils";

export const FORM_LAYOUT_PROPS = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export const SINGLE_ITEM_LAYOUT_PROPS = {
  wrapperCol: { offset: 12, span: 12 },
};

export const MONTH_FORMAT = "YYYY-MM";

export const DEFAULT_CAPTCHA_URL =
  "https://via.placeholder.com/160x60.png?text=Aqui%20aparecerá%20el%20codigo";

export const ENDPOINTS = {
  SESSION_START: template`${"API_HOST"}/facturio/session-start`,
  PROCESS: template`${"API_HOST"}/facturio/${"sessionId"}/process`,
  STATUS: template`${"API_HOST"}/facturio/${"processId"}/status`,
};

export const LU_OPERATION_TYPE: HashInt = {
  1: "Recibidas",
  2: "Emitidas",
};

export const LU_PROCESS_ERROR = {
  CAPTCHA: "El código ingresado no coincide, favor de volver a intentar",
  LOGIN: "El RFC y contraseña no son correctos, favor de volver a intentar",
  EXPIRATION: "La sesión ya ha experido, favor de recargar el sitio",
  IN_PROGRESS:
    "Ya existe una solicitud en proceso, debe esperar a que termine para ejecutar otra",
};

export const LU_STATUS_NAME = {
  COMPLETED: "Completed",
  ERROR: "Error",
};
