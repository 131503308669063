import axios from "axios";
import fileDownload from "js-file-download";
import { HashStr } from "./interface";

export const template = (strings: TemplateStringsArray, ...keys: string[]) => {
  return (...values: HashStr[]) => {
    const dict: HashStr = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join("");
  };
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const downloadFile = (url: string, filename: string) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((resp) => {
      if (resp.status !== 200) {
        return;
      }
      fileDownload(resp.data, filename);
    });
};
